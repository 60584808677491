@import '../../scss/style.scss';

footer {
  background-color: $colorBlue;
}

.footer__wrapp {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 5.2em 0;
  width: 100%;
  gap: 5%;

  .footer-back-img {
    position: absolute;
    right: 0;
    top: -10em;
    background-image: url('../../assets/images/1486217915.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 18.5vw;
    height: 18.5vw;
  }
}

.footer__left {
  display: flex;
  flex-direction: column;
  padding: 0 3.8em;
  width: 30%;
  gap: 3em;

  img {
    width: 100%;
    height: auto;
  }
}

.footer__psfs-wrapp {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  width: 62%;

  .psfs {
    align-self: center;
    margin-bottom: 0.3em;
    border: 0.3em solid $colorWhite;
    border-top-right-radius: 0.7em;
    border-top-left-radius: 0.7em;
    width: 100%;

    span {
      font-family: 'Rubik';
      font-size: 3.4vw;
      font-weight: 700;
      color: $colorWhite;
    }
  }

  & .psfs-text {
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      // font-family: 'Rubik';
      font-size: 0.9vw;
      font-weight: 400;
      line-height: 120%;
      color: $colorWhite;
    }
  }
}

.footer__copyright {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Rubik';
  font-size: 1vw;
  font-weight: 500;
  line-height: normal;
  color: $colorLightBlue;
}

.footer__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;

  ul {
    list-style: none;
  }

  h4 {
    padding-top: 0.2em;
    margin-bottom: 1em;
    font-family: 'Rubik';
    font-size: 1.67vw;
    font-weight: 700;
    color: $colorWhite;
  }
}

.footer__right-wrapp {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2em;
}

.footer__right-center {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10%;

  .footer__info {
    font-family: 'Rubik';
    font-size: 1.24vw;
    font-weight: 400;
    line-height: normal;
    color: $colorWhite;
    width: 40%;
  }
}


.footer__info-list {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}

.footer__right-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.2em;
}

.footer__link {
  display: flex;
  align-items: center;
  font-family: 'Rubik';
  font-size: 1vw;
  font-weight: 500;
  line-height: normal;
  color: $colorWhite;

  img {
    width: 2.2vw;
    margin-right: 0.6em;
  }
}

@media (max-width: 1024px) {
  .footer__wrapp {
    flex-direction: column;
    gap: 2em;
  }

  .footer__left {
    padding: 0px 24.8em;
    width: 100%;
  }

  .footer__right {
    width: 100%;
  }

  .footer__copyright {
    font-size: 2.34vw;
  }

  .footer__psfs-wrapp .psfs-text span {
    font-size: 2vw;
  }


  .footer__psfs-wrapp .psfs {
    border: 0.5em solid #fff;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  .footer__psfs-wrapp .psfs span {
    font-size: 6.4vw;
  }

  .footer__psfs-wrapp {
    width: 52%;
  }

  .footer__right h4 {
    font-size: 3.13vw;
  }

  .footer__right-center .footer__info {
    width: 45%;
    font-size: 2.34vw;
  }

  .footer__link {
    font-size: 2.34vw;
  }

  .footer__link img {
    width: 5.2vw;
    margin-right: 0.6em;
  }

  .footer__info-list {
    gap: 2em;
  }

  .footer__right-wrapp {
    gap: 4em;
  }

  .footer__wrapp .footer-back-img {
    top: 25em;
    width: 24.5vw;
    height: 24.5vw;
  }
}

@media (max-width: 576px) {

  .footer__wrapp,
  .footer__right-wrapp {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer__wrapp .footer-back-img {
    right: 10%;
    top: -33em;
    width: 65vw;
    height: 65vw;
}

  .footer__wrapp {
    padding-top: 35em;
  }

  .footer__right {
    width: 90%;
  }

  .footer__psfs-wrapp {
    width: 62%;
}

  .footer__left {
    padding: 0;
    width: 74%;
    gap: 5em;
  }

  .footer__right-center,
  .footer__right-list {
    width: 90%;
    gap: 5em;
  }

  .footer__psfs-wrapp .psfs {
    margin-bottom: 1em;
    border: 0.9em solid #fff;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  .footer__psfs-wrapp .psfs span {
    font-size: 13.4vw;
  }

  .footer__psfs-wrapp .psfs-text span {
    line-height: 100%;
    font-size: 3.2vw;
  }

  .footer__copyright {
    // margin-bottom: 3em;
    font-size: 4vw;
  }

  .footer__right h4 {
    margin-bottom: 0.8em;
    font-size: 6.6vw;
  }

  .footer__right-center .footer__info,
  .footer__link {
    width: 100%;
    font-size: 4vw;
  }

  .footer__link img {
    width: 10.5vw;
  }

  .footer__info-list,
  .footer__right-list {
    gap: 2.8em;
  }

  .footer__info-list {
    margin-bottom: 2.8em;
  }

  .footer__right-list {
    flex-direction: column;
    width: 90%;
  }
}