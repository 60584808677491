@import '../../../scss/style.scss';

.data-czas {
    position: relative;
}

.day-wrapp {
    position: relative;
}

.sale {
    position: absolute;
    top: -1.2em;
    left: 0.3em;
    font-size: 0.7vw;
    font-weight: 400;

    @media (max-width: 1024px) {
        font-size: 1.6vw;
    }

    @media (max-width: 576px) {
        font-size: 2.8vw;
        left: 0.2em;
    }
}

.sale.minus {
    color: $colorBlue;
}

.sale.plus {
    color: $colorYellow;
}

.disabled-time {
    cursor: default;
    color: #ccc;
}

.react-datepicker {
    width: 100%;
    border: 1px solid $colorDarkBlue;
    background-color: transparent;
    border-radius: 1.5em;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 1.5em;
}

.react-datepicker__header {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 1.5em;
    background-color: $colorWhite;
    padding: 1.05em;
    height: 6em;
    border: none;
    border-bottom: 1px solid $colorDarkBlue;
}

// .react-datepicker__header--time {
//     height: 90px;
// }

.react-datepicker__time-container .react-datepicker__time {
    background-color: transparent;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: transparent;
}

.react-datepicker__time-container {
    // border-left: 1px solid $colorDarkBlue;
    border-left: none;
}

.react-datepicker__month-container {
    width: 70%;
    border-right: 1px solid $colorDarkBlue;
    // border-right: none;
}

.react-datepicker__time-container {
    width: 30%;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 33%;
}

.react-datepicker__navigation--previous {
    left: 3%;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: $colorDarkBlue;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-family: Rubik;
    font-size: 1.25vw;
    text-transform: uppercase;
}

.react-datepicker-time__header {
    text-transform: lowercase;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 12%;
    font-family: Rubik;
    font-size: 0.9vw;
    color: $colorDarkBlue;
}

.react-datepicker__day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 2em;
}

.react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    // padding-top: 12px;
    margin-top: auto;
    padding-bottom: 0;
}


.react-datepicker__month {
    margin: 0;
    padding: 1.05em;
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    justify-content: space-between;
}


.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 1.5em;
    background: $colorDarkBlue;
    color: $colorWhite;
    font-weight: bold;
    transition: all .2s ease-in-out;
}


.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: $colorBlue;
    border-radius: 1.5em !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    transition: all .2s ease-in-out;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 1.5em !important;
    background-color: $colorBlue !important;
    color: $colorWhite;

    @media(max-width: 1024px) {
        background-color: $colorDarkBlue !important;
    }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: $colorBlue;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: $colorBlue;
    color: $colorWhite;
}


.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc;
    text-decoration: line-through;
    pointer-events: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 8px;
    padding: 1.05em 0;
    height: 100% !important;
    overflow-y: auto;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 2.3em;
    width: 100%;
    font-size: 0.9vw;
    color: $colorDarkBlue;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
    border-bottom-right-radius: 1.4em;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background: $colorDarkBlue;
    color: $colorWhite;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    display: none;
}

.all-disabled-time {
    opacity: 0.3;
    // cursor: none;
    pointer-events: none;
}

.disabled-time // .disabled-date 

    {
    opacity: 0.3;
    // cursor: none;
    pointer-events: none;
    text-decoration: line-through;
}

.react-datepicker__navigation {
    top: 4.2%;
    height: 10%;
    width: 5%;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    // border-color: red;
    border-style: solid;
    border-width: 0.2vw 0.2vw 0 0;
    content: "";
    display: block;
    height: 0.6vw;
    position: absolute;
    top: 6px;
    width: 0.6vw;
}

.react-datepicker__navigation:hover *::before {
    border-color: $colorBlue;
}

@media(max-width: 1024px) {

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-size: 2.34vw;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        font-size: 2.08vw;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        font-size: 2.08vw;
        height: 2.1em;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
        height: 1vw;
        width: 1vw;
    }

    .react-datepicker__header {
        padding: 2.7em;
        height: 14em;
        border-top-left-radius: 16px;
    }

    .react-datepicker__month {
        padding: 2.7em;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        padding: 2.7em 0;
    }

    .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
        border-top-right-radius: 16px;
    }

    .react-datepicker {
        border-radius: 16px;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        border-radius: 2.08vw;
    }
}

@media(max-width: 576px) {

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-size: 4.2vw;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 13%;
        font-size: 4vw;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        font-size: 4vw;
        height: 2em;
    }

    .react-datepicker {
        border-radius: 16px;
    }

    .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
        border-top-right-radius: 16px;
    }

    .react-datepicker__header {
        padding: 16px 1.42em;
        border-top-left-radius: 16px;
        height: 26em;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
        top: 2px;
        border-width: 0.6vw 0.6vw 0 0;
        height: 1.8vw;
        width: 1.8vw;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 100%;
        border-bottom-right-radius: 16px;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        border-radius: 16px;
    }

    .react-datepicker__navigation {
        top: 16px;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        border-radius: 16px;
    }

}

//   @media screen and (max-width: 576px) {
//     .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,
//     .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name,
//     .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
//         font-size: 16px;
//     }

//     .react-datepicker__header--time {
//         height: 81px;
//     }
// }