@import '../../scss/style.scss';

@mixin heading-1 {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 900;
  // font-size: 48px;
  font-size: 3.7vw;
  line-height: 100%;
  color: $colorBlue;
}

@font-face {
  font-family: 'Venus Rising';
  src: url(../../assets/fonts/Venus_Rising.ttf) format('opentype');
}

@mixin heading-4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 1.9vw;
  line-height: 120%;
  color: $colorDarkBlue;
}

.header-container {
  display: flex;
  align-items: center;
  padding: 70px 0;
  background: linear-gradient(180deg, $colorWhite 0%, #F3F9FF 100%);
  height: 100vh;

}

.header-content-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  .button {
    @include btn-reset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34%;
    height: 2.7vw;
    font-size: 1vw;

    @include button-effect;

    &:active {
      background: $colorDarkBlue;
    }

    a {
      color: $colorWhite;
    }
  }

  .left-side {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    width: 50%;
  }

  .left-side-title {
    @include heading-1;
    margin-bottom: 1rem;
    // font-size: 2.9vw;
  }

  .left-side-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    @include heading-4;
    font-size: 1.56vw;
    font-weight: 700;
  }

  .place-wrapper {
    display: flex;
    margin-bottom: 2rem;
  }

  .place-title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.94vw;
    line-height: 100%;
    color: $colorOrange;

    & img {
      width: 1.1vw;
    }

    & span {
      padding-left: 10px;
    }

  }

  .right-side {
    width: 45%;
    height: 60vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 1024px) {

  .header-container {
    padding: 12.13vw 0;

    @media (orientation: landscape) {
      height: 100%;
    }
  }

  .header-content-wrapper {
    flex-direction: column-reverse;
    width: 100%;
  }

  .header-content-wrapper .right-side {
    width: 60%;
    height: 30vh;
  }

  .header-content-wrapper .left-side {
    align-items: center;
    width: 100%;
  }

  .header-content-wrapper .left-side-title {
    font-size: clamp(32px, 8vw, 62px);
    // font-size: 8vw;
  }

  .header-content-wrapper .place-title {
    padding: 0;
    font-size: 2vw;
  }

  .header-content-wrapper .place-title img {
    width: 3vw;
  }

  .header-content-wrapper .place-wrapper {
    padding: 0;
  }

  .header-content-wrapper .left-side-description {
    margin-bottom: 5rem;
    font-size: clamp(18px, 3vw, 23px);
    // font-size: 3vw;
  }

  .header-content-wrapper .button {
    font-size: clamp(18px, 3vw, 23px);
    // font-size: 3vw;
    border-radius: 26px;
    width: 45%;
    height: 8.5vw;
  }
}


@media (max-width: 576px) {
  .header-content-wrapper {
    flex-direction: column-reverse;
    width: 100%;
  }

  .header-content-wrapper .right-side {
    width: 86%;
  }

  .header-content-wrapper .left-side {
    align-items: center;
    width: 100%;
  }

  .header-content-wrapper .left-side-title {
    font-size: 9vw;
  }

  .header-content-wrapper .place-title {
    padding: 0;
    font-size: 3vw;
  }

  .header-content-wrapper .place-title img {
    width: 3vw;
  }

  .header-content-wrapper .place-wrapper {
    padding: 0;
  }

  .header-content-wrapper .left-side-description {
    margin-bottom: 8rem;
    font-size: 5vw;
  }

  .header-content-wrapper .button {
    font-size: 5vw;
    border-radius: 16px;
    width: 60%;
    height: 12.5vw;
  }

  .header-content-wrapper .place-wrapper {

    margin-bottom: 4rem;
  }

  .header-container {
    padding: 14vw 0 2vw 0;
  }
}